<template lang="pug">
.warning-message(:class="type")
  ui-icon.icon(:icon="UiIconNames.Icon_Info")
  span
    slot
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';
import UiIcon from "@/components/ui/icon/UiIcon.vue";
import UiIconNames from "@/components/ui/icon/UiIconNames";

export default defineComponent({
  name: "WarningMessage",
  components: {
    UiIcon,
  },
  props: {
    type: {
      type: String as PropType<'default'|'danger'|'warning'|'success'>,
      default: 'default',
    },
  },
  setup() {
    return {
      UiIconNames,
    }
  }
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixin/fonts';

.warning-message {
  display: flex;
  flex-flow: row;
  gap: 8px;

  .icon {
    margin: 1px 0;
  }

  span {
    @include label-13-18;
    overflow: hidden;
  }

  &.default {
    color: var(--main-text-color);

    .icon {
      color: var(--main-red-color);
    }
  }

  &.success {
    color: var(--main-success-color);
  }

  &.warning {
    color: var(--text-warning-color);
  }

  &.danger {
    color: var(--main-red-color);
  }
}
</style>
